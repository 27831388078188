import React from "react"
import { Link } from "gatsby"
import Video from "../components/Video"

const PromoVideo = props => (
  <div className={props.cssClass}>
    <div className="container">
      <div className="promo-video__media">
        <Video videoSrcURL={props.videoSrcURL} videoTitle="Promo Video" />
        <p className="promo-video__copyright">{props.videoCopyright}</p>
      </div>
      <div className="promo-video__text">
        <div className="text">
          {props.heading && <h3>{props.heading}</h3>}
          {props.children}
        </div>
        <Link to={props.btnAction} className="btn btn--primary">
          {props.btnLabel}
        </Link>
      </div>
    </div>
  </div>
)

export default PromoVideo
